<template lang="pug">
  .car-class-settings
    AppNestedRoutesTabs(
      :routes="routes"
      :default-prevented="isDirty"
    )
</template>

<script>
  // mixins
  import withStoreModule from "@/mixins/withStoreModule"

  // store modules
  import carClassSettingsModule from "@/config/store/company_system/car_class_settings/car_classes"
  import carModelsSettingsModule from "@/config/store/company_system/car_class_settings/car_models"

  const carClassSettingsMixin = withStoreModule(carClassSettingsModule, {
    name: "carClassSettingsMixin",
    readers: { isCarClassDirty: "isDirty" }
  })

  const carModelsSettingsMixin = withStoreModule(carModelsSettingsModule, {
    name: "carModelSettingsMixin",
    readers: { isCarModelDirty: "isDirty" }
  })

  export default {
    components: {
      AppNestedRoutesTabs: () => import("@/components/AppNestedRoutesTabs")
    },

    mixins: [carClassSettingsMixin, carModelsSettingsMixin],

    computed: {
      isDirty({ isCarClassDirty, isCarModelDirty }) {
        return isCarClassDirty || isCarModelDirty
      },

      routes() {
        return [
          {
            name: "CarClass",
            title: this.$t("header.title.car_class")
          },
          {
            name: "CarModel",
            title: this.$t("header.title.car_model")
          }
        ]
      }
    }
  }
</script>

<style lang="sass" scoped></style>
